import React, { Component } from "react";
import Menu from './Menu'
import Content from "./Content";
import '../styles/App.css'
import '../styles/reactGridStyles.css'
import '../styles/reactResizableStyles.css'
import { blogAPI, twitterAPI } from '../utils/API';
import * as DataEngine from '../utils/DataEngine';
import Modal from 'react-modal';
import ReactLoading from 'react-loading';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle, faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import * as shuffle from 'shuffle-array';


class Blog extends Component {

  constructor(props) {
    super(props)
    this.state = {
      cachedPosts: [],
      posts: [],
      layout: [],
      columns: 3,
      padding: 10,
      showModal: false,
      modalPost: {},
      gridWidth: 0,
      doneLoading: false,
      rssOn: false,
      twitterOn: false,
      appsOn: false
    }

    this.calculateLayouts = this.calculateLayouts.bind(this);
    this.processBody = this.processBody.bind(this);
    this.getPost = this.getPost.bind(this);
    this.closePost = this.closePost.bind(this);
    this.processModalBody = this.processModalBody.bind(this);
    this.openInNewTab = this.openInNewTab.bind(this);
    this.filterByType = this.filterByType.bind(this);
    this.resetFilter = this.resetFilter.bind(this);
  }

  async componentDidMount() {
    // console.log("COMPONENT MOUNTED");
    // if (process.env.NODE_ENV == 'production')
    // {
    //   console.log("PRODUCTION BUILD");
    // }

    try {

      //console.log("Getting Twitter: ");
      let data1 = await twitterAPI.get("/");
      let processed_twitter = DataEngine.processTwitter(data1);
      
      //console.log(processed_twitter);

      //console.log("Getting Tumblr: ");
      let data2 = await blogAPI.get("/");
      let processed_blog = DataEngine.processBlogs(data2.data.response.posts);

      //console.log(processed_blog);
      let mergedData = DataEngine.mergeData(processed_blog, processed_twitter)

      // console.log("---- MERGED DATA -----")
      // console.log(mergedData)
      // console.log("----------------------")

      this.setState({
        posts: mergedData,
        cachedPosts: mergedData
      }, () => {
        let layouts = this.calculateLayouts(this.state.posts);
        // console.log('---- LAYOUTS ----');
        // console.log(layouts.layout);
        // console.log("-----------------");
        this.setState({
          layout: layouts.layout,
          columns: layouts.columns,
          doneLoading: true
        })
      });

    }
    catch (e) {
      console.log(e);
    }

  }

  calculateLayouts(data)
  {
    
    //console.log("Calculating layouts")
    const checkSmartPhone = window.matchMedia('(max-width: 768px)');
    const checkDesktop = window.matchMedia('(min-width: 1200px)');
    if (checkSmartPhone.matches) {
      //console.log("SYSTEM IS SMARTPHONE OR SMALL SCREEN");
      var positions = []
      data.map((post, idx) => positions.push({ i: post.id, x: 0, y: idx, w: 2, h: 1, static: true }))
      this.setState({gridWidth: window.innerWidth})

      return {

        "layout": positions,
        "columns": 1
      };
    }

    // large screen, desktop:
    if (checkDesktop.matches) {
      //console.log("SYSTEM IS DESKTOP")
      var positions = [];
      var curr_y = 0;
      var idx = 0;
      for (idx = 0; idx < data.length; idx++)
      {
        positions.push({ i: data[idx].id, x: (idx % 3), y: curr_y, w: 1, h: 1, static: true, title: data[idx].title })
        if ((idx % 3) == 0 && idx != 0) {
          curr_y += 1;
        }
      }
      
      this.setState({gridWidth: window.innerWidth * 0.75})
      //console.log(positions)
      return {
        "layout": shuffle(positions, { 'copy': true }),
        "columns": 3
      };
    }

    // else, there's a middle ground size:
    //console.log("SYSTEM IS SOME MEDIUM DEVICE")
    var positions = [];
    var curr_y = 0;
    var idx = 0;
    for (idx = 0; idx < data.length; idx++) {
      positions.push({ i: data[idx].id, x: (idx % 2), y: curr_y, w: 1, h: 1, static: true })
      if ((idx % 2) == 0 && idx != 0) {
        curr_y += 1;
      }
    }
    
    this.setState({gridWidth: window.innerWidth * 0.90})
    //console.log(positions)
    return {
      "layout": positions,
      "columns": 2
    };

  }



  processBody(post)
  {
    if (post.type == "tumblr" || post.type == "apps") {
      return <div className="body" dangerouslySetInnerHTML={{ __html: post.body }}></div>
    }
    else {
      return <div className="body">{post.body}</div>
    }
  }

  getPost(id, event) {
    let focusPost = this.state.posts.find(p => p.id == id);
    //console.log(focusPost);
    this.setState({ modalPost: focusPost }, () => {
      this.setState({ showModal: true });
    });

  }

  processModalBody(post) {
    if (post.type == "tumblr" || post.type == "apps") {
      return <div className="modalBody" dangerouslySetInnerHTML={{ __html: post.body }}></div>
    }
    else {
      return <div className="modalBody">{post.body}</div>
    }
  }

  openInNewTab(url, event) {
    var win = window.open(url, '_blank');
    win.focus();
  }

  closePost() {
    this.setState({ showModal: false });
  }


  filterByType(post_type, event)
  {
    if(post_type == "apps")
    {
      //console.log("Filtering by APPS!")
      if(this.state.appsOn)
      {
        this.setState({appsOn: false});
        this.resetFilter();
        return;
      }
      else
      {
        this.setState({appsOn: true, rssOn: false, twitterOn: false});
      }
    }

    if(post_type == "tumblr")
    {
      //console.log("Filtering by TUMBLR!")
      if(this.state.rssOn)
      {
        this.setState({rssOn: false});
        this.resetFilter();
        return;
      }
      else
      {
        this.setState({rssOn: true, appsOn: false, twitterOn: false});
      }
    }

    if(post_type == "twitter")
    {
      console.log("Filtering by TWITTER!")
      if(this.state.twitterOn)
      {
        this.setState({twitterOn: false});
        this.resetFilter();
        return;
      }
      else
      {
        this.setState({twitterOn: true, rssOn: false, appsOn: false});
      }
    }

    //console.log("Filtering by " + post_type)
    let filteredPosts = this.state.cachedPosts.filter(
      function (post) {
        return post.type == post_type
      }
    );

    this.setState({
      posts: filteredPosts
    }, () => {
      let layouts = this.calculateLayouts(this.state.posts);
     
      this.setState({
        layout: layouts.layout,
        columns: layouts.columns
      })
    });
  }

  resetFilter()
  {
    this.setState({ posts: this.state.cachedPosts }, () => {
      let layouts = this.calculateLayouts(this.state.posts);
      this.setState({
        layout: layouts.layout,
        columns: layouts.columns
      })
    });
  }




  render() 
  {
    return (
      <div>
        <Modal
            isOpen={this.state.showModal}
            onRequestClose={this.closePost}
            className="Modal"
            overlayClassName="Overlay">
            <div className="modalMenu">
                <span className="modalTitle"><FontAwesomeIcon icon={this.state.modalPost.icon}></FontAwesomeIcon>&nbsp;{this.state.modalPost.title}</span>
                <span className="modalMenuOptions">
                    <span className="modalIcon"><FontAwesomeIcon icon={faTimesCircle} onClick={this.closePost}></FontAwesomeIcon></span>
                    <span className="modalIcon"><FontAwesomeIcon icon={faExternalLinkAlt} onClick={(e) => this.openInNewTab(this.state.modalPost.url, e)}></FontAwesomeIcon></span>
                </span>
                
            </div>
            {this.processModalBody(this.state.modalPost)}
            
        </Modal>
        <Menu
          filterByType={this.filterByType}
          resetFilter={this.resetFilter}
          openInNewTab={this.openInNewTab}
          appsOn={this.state.appsOn}
          rssOn={this.state.rssOn}
          twitterOn={this.state.twitterOn}
        >
        </Menu>
        {
          !this.state.doneLoading ?
          (
            <div className="loadingScreen">
              <ReactLoading type={"bars"} color={"#35a7ff"}/>
              Loading data...
            </div>
          ) : (
          <Content
            gridWidth={this.state.gridWidth}
            layout={this.state.layout}
            columns={this.state.columns}
            padding={this.state.padding}
            posts={this.state.posts}
            processBody={this.processBody}
            getPost={this.getPost}
          >  
          </Content>
         )
        }
      </div>
    );
  }

}
export default Blog;