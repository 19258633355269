import { faTwitterSquare, faAppStoreIos } from "@fortawesome/free-brands-svg-icons";
import * as moment from 'moment';
import { faRssSquare } from "@fortawesome/free-solid-svg-icons";

export function processBlogs(raw_data)
{
    var processedData = []
    
    raw_data.forEach(
        raw_post => {
            
            var post_type = "tumblr";
            var post_icon = faRssSquare;

            if (raw_post.tags != null)
            {
                if (raw_post.tags.length > 0)
                {
                    raw_post.tags.forEach(function(el) {
                        if (el == "swift")
                        {
                            post_type = "apps";
                            post_icon = faAppStoreIos
                        }
                    });
                }
            }

            let dateParsed =  moment(raw_post.date, 'YYYY-MM-DD HH:mm:ss');

            processedData.push({
                id: 'tumblr'+raw_post.id,
                title: raw_post.title,
                post_age: dateParsed.fromNow(),
                date_unix: dateParsed.unix(),
                body: raw_post.body,
                icon: post_icon,
                tags: raw_post.tags,
                url: raw_post.post_url,
                type: post_type
            });
            
        }
    )
    return processedData;
}

export function processTwitter(raw_data)
{
    //console.log(raw_data);
    var processedData = []
    raw_data.data.forEach(raw_tweet => {

        var tags_to_add = ["tag1"]
        let raw_hashes = raw_tweet.entities.hashtags;
        if(raw_hashes.length > 0)
        {
            tags_to_add = []
            raw_hashes.forEach(hashObj => {
                tags_to_add.push(hashObj.text)
            });
        }
        
        processedData.push({
            id: 'twitter'+raw_tweet.id_str,
            title: "bionicly",
            body: raw_tweet.text,
            icon: faTwitterSquare,
            post_age: moment(raw_tweet.created_at, 'ddd MMM DD HH:mm:ss ZZ YYYY').fromNow(),
            date_unix: moment(raw_tweet.created_at, 'ddd MMM DD HH:mm:ss ZZ YYYY').unix(),
            type: "twitter",
            url: 'http://twitter.com/bionicly/status/' + raw_tweet.id_str,
            tags: tags_to_add
        })
    });

    //console.log(processedData);

    return processedData;
}

export function mergeData(processedBlogs, processedTwitter)
{
    return processedBlogs.concat(processedTwitter).sort((a, b) => {
        if (a.date_unix < b.date_unix) {
            return 1;
        } else if (a.date_unix > b.date_unix) {
            return -1;
        } else {
            return 0;
        }
    });
}