import React from "react";
import { Link } from "react-router-dom";
import logo from '../images/bioniclylabs_logo.png';

export default function Landing() {
  return (
    <div className="landingContainer">
        <div className="landingHeader">
            <div >
                <img src={logo} className="landingLogo"/>
            </div>
        </div>
        <div className="landingCenter">
            At bioniclylabs, we want to improve how people and organizations think about new ideas and innovation.
            <br></br><br></br>
            <Link to="/blog" className="textButton">Blog</Link>
            <a href="https://www.bioniclylabs.com/bionicly_resume_032621.pdf" className="textButton">Resume</a>
        </div>
        
    </div>
  );
}
