import React, { Component } from "react";
import "../styles/App.css"
import * as GridLayout from 'react-grid-layout';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";



class Content extends Component 
{
    constructor(props)
    {
        super(props)
        this.containerRef = React.createRef()
    }



    
    render() 
    {
        if(this.props.showResume)
        {
            return null;
        }

        //const screenConfig = this.calculateLayouts(this.state.posts);
        return (
            <div className="contentContainer" ref={this.containerRef}>
                <GridLayout 
                    className="layout" 
                    layout={this.props.layout} 
                    cols={this.props.columns} 
                    rowHeight={300}
                    width={this.props.gridWidth}>
                    {
                        this.props.posts.map(
                            (post) =>
                                <div className="post" key={post.id} onClick={(e) => this.props.getPost(post.id, e)}>
                                    <div className="title">
                                        <span className="icon"><FontAwesomeIcon icon={post.icon}></FontAwesomeIcon></span>
                                        {post.title}&nbsp;
                                        <span className="timestamp">{post.post_age}</span>
                                    </div>
                                    {this.props.processBody(post)}
                                    
                                </div>


                        )
                    }

                </GridLayout>
            </div>
        );
    }
}

export default Content;