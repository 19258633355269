import React, { Component } from "react";
import "../styles/App.css"
import bionic_logo from '../images/bionic_logo.png';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRssSquare, faIdCard } from '@fortawesome/free-solid-svg-icons'
import { faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { faTwitterSquare } from '@fortawesome/free-brands-svg-icons'
import { faAppStoreIos } from '@fortawesome/free-brands-svg-icons';

import classNames from 'classnames';

class Menu extends Component
{
    // constructor(props)
    // {
    //     super(props)
    //     this.state =
    //     {
    //         rssOn: false,
    //         twitterOn: false,
    //         appsOn: false
    //     }
    // }

    render()
    {
        var appClass = classNames({
            'menuIcon': true,
            'active': this.props.appsOn
            
        });

        var rssClass = classNames({
            'menuIcon': true,
            'active': this.props.rssOn
            
        });

        var twitterClass = classNames({
            'menuIcon': true,
            'active': this.props.twitterOn
            
        });

        return(
            <div className="menu">
                <div className="menuLogo">
                    <img src={bionic_logo} className="logo"/>
                </div>
                <div className="menuIcon">
                    <FontAwesomeIcon icon={faLinkedin} onClick={(e) => this.props.openInNewTab('http://www.linkedin.com/in/bionicly/', e)}/>
                </div>
                <div className={twitterClass}>
                    <FontAwesomeIcon icon={faTwitterSquare} onClick={(e) => this.props.filterByType("twitter", e)}/>
                </div>
                <div className={rssClass}>
                    <FontAwesomeIcon icon={faRssSquare} onClick={(e) => this.props.filterByType("tumblr", e)}/>
                </div>
                <div className={appClass}>
                    <FontAwesomeIcon icon={faAppStoreIos} onClick={(e) => this.props.filterByType("apps", e)}/>
                </div>
                <div className="menuIcon">
                   
                    <FontAwesomeIcon icon={faIdCard} onClick={(e) => window.open('bionicly_resume_032621.pdf')}/>
                </div>
            </div>
        );
    }
}

export default Menu;