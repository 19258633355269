// import axios from "axios";

// export default axios.create({
//   baseURL: "/streambionicly",
//   responseType: "json"
// });


import axios from "axios";

// For common config
axios.defaults.headers.post["Content-Type"] = "application/json";

const blogAPI = axios.create({
    baseURL: 'https://4g5lv13wy6.execute-api.us-east-2.amazonaws.com/bioniclymirror/streambionicly'
    //baseURL: '/services/tumblr.php'
});

const twitterAPI = axios.create({
    baseURL: 'https://4g5lv13wy6.execute-api.us-east-2.amazonaws.com/bioniclymirror/tweetbionicly'
    //baseURL: '/services/bionictweets.php'
});


export {
    blogAPI,
    twitterAPI
};